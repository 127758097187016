import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import InfoImage from "../components/info-image/info-image";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Banner from "../components/banner/banner";
import { bannerCMS } from "../utils/generalInformation";
import { subpageContent, sliderCompanies, testimonials } from "../utils/services/subpageInformation";




// markup
const Intervention = () => {


  return (
    <Layout
      SEOTitle={"Third-Party Intervention - United Legal Assistance"}
      SEODescription={"Our fleet management solution has two primary aspects. Firstly, we try to reduce the frequency of motor accidents by working directly with each driver to make their driving safer and thereby minimise the occurrence of claims."}
      footerDisclaimer={""}>

      <InfoImage props={subpageContent} origin="interventionContent" />

      <Banner props={bannerCMS} />

      <ImageCarousel props={sliderCompanies} />

      <ImageCarousel props={testimonials} />

    </Layout>
  )

}

export default Intervention